import Header from "../../components/common/Header";
import About from "../../components/picdict/About";
import ContactForm from "../../components/common/Contact";
import Footer from "../../components/common/Footer";
import HowTo from "../../components/picdict/HowTo";
import { Helmet } from "react-helmet";

function PicDict() {
  return (
    <div className="PicDict">
      <Helmet
        title="PicDictの使用方法"
        link={[{ rel: "canonical", href: "https://matsuki-takeshi.com/" }]}
        meta={[
          {
            name: "description",
            content:
              "PicDictについての使用方法の説明。",
          },
          {
            property: "og:description",
            content:
              "PicDictの使用方法の説明。",
          },
          { property: "og:title", content: "PicDictの使用方法" },
          { property: "og:type", content: "使用方法の説明" },
          { property: "og:url", content: "https://matsuki-takeshi.com/picdict" },
          { property: "keywords", content: "picdict" },
        ]}
      />
      <header className="PicDict-header">
        <Header />
      </header>
      <div>
        <div>
          <h1 style={{ fontSize: 26, textAlign: "center" }}>
            PicDict
          </h1>
          <About />

          
          <h2 style={{ textAlign: "center" }}>How To</h2>

          <HowTo />

          <h2 style={{ textAlign: "center" }}>Contact</h2>

          <ContactForm />

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default PicDict;
